import HousePricePredict from "@/pages/HousePrice/index.vue";
import Home from "@/pages/Home/index.vue"
import VueRouter from "vue-router";


export default new VueRouter({
    mode:'history',
    routes:[
        {
            path:'/housepricepredict',
            component:HousePricePredict,
        },
        {
            path:'/',
            component:Home,
            
        },
    ]
})