var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"portfolio",attrs:{"id":"section1"}},[_c('div',{staticClass:"vertical-dashed-line"}),_c('div',{staticClass:"dashed-line-with-circle"}),_c('div',{staticClass:"dashed-line-with-circle-end"}),_c('div',{staticClass:"container-fluid"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"row justify-content-center main-content3"},[_c('div',{staticClass:"col-10 col-md-5 col-xxl-4 preview3"},[_vm._m(2),_c('div',{staticClass:"title fs-1 fw-bolder mt-1 mt-md-3"},[_vm._v("新北市房價預測系統")]),_c('div',{staticClass:"fs-3 tag fw-bolder mt-1 mt-md-3"},[_vm._v("data processing")]),_vm._m(3),_c('div',{staticClass:"linkArea"},[_c('router-link',{staticClass:"link",attrs:{"to":"/housepricepredict"}},[_c('i',{staticClass:"bi bi-box-arrow-up-right"}),_c('div',{staticClass:"linkTxt"},[_vm._v("Go Try !")])])],1)]),_vm._m(4)]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row main-contnet justify-content-center position-relative"},[_c('div',{staticClass:"col-10 col-md-5 col-xxl-4 preview"},[_c('div',{},[_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/rioticon.png"),"alt":"lolPic"}})]),_c('div',{staticClass:"title fs-1 fw-bolder mt-1 mt-md-3"},[_vm._v("LOL player search")]),_c('div',{staticClass:"fs-3 tag fw-bolder mt-1 mt-md-3"},[_vm._v("Work with APIs")]),_c('div',{staticClass:"fw-bolder mt-2 main-text"},[_c('p',[_vm._v("LOL玩家查詢系統，利用RIOT官方api製作玩家資訊查訊系統，包含常用英雄、對戰紀錄...等")])]),_c('div',{staticClass:"linkArea"},[_c('a',{staticClass:"link"},[_c('i',{staticClass:"bi bi-cone-striped"}),_c('div',{staticClass:"linkTxt"},[_vm._v(" 開發中...")])])])]),_c('div',{staticClass:"col-10 col-md-5 col-xxl-4 imgArea"},[_c('div',{staticClass:"image"},[_c('img',{staticClass:"lol-wallpaper",attrs:{"src":require("../../assets/lolwallpaper1.png"),"alt":"lol"}})])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row justify-content-center main-contnet2 align-items-center"},[_c('div',{staticClass:"col-10 col-md-5 col-xxl-4 imgArea2"},[_c('div',{staticClass:"image"},[_c('img',{staticClass:"mapImg",attrs:{"src":require("../../assets/taipei_cafe_list.jpg"),"alt":"lol"}})])]),_c('div',{staticClass:"col-10 col-md-5 col-xxl-4 preview2"},[_c('div',{},[_c('img',{staticClass:"icon-leaf",attrs:{"src":require("../../assets/taipei_cafe_list_logo.png"),"alt":"lolPic"}})]),_c('div',{staticClass:"title fs-1 fw-bolder mt-1 mt-md-3"},[_vm._v("Taipei Cafe List")]),_c('div',{staticClass:"fs-3 tag fw-bolder mt-1 mt-md-3"},[_vm._v("Work with Map")]),_c('div',{staticClass:"fw-bolder mt-2 main-text"},[_c('p',[_vm._v("利用leaflet製作台北市咖啡廳地圖")])]),_c('div',{staticClass:"linkArea"},[_c('a',{staticClass:"link",attrs:{"href":"https://tim56887.dev/tmycafe.github.io/#/","target":"_blank"}},[_c('i',{staticClass:"bi bi-box-arrow-up-right"}),_c('div',{staticClass:"linkTxt"},[_vm._v("Go Try !")])])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{},[_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/ntp.png"),"alt":"lolPic"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"fw-bolder mt-1 main-text"},[_c('p',[_vm._v("使用新北市政府提供之房價實價登錄資料。 利用python Pandas進行資料清理，經過系列的前處理步驟，處理缺失值、轉換類別資料特徵等，使得原始資料達到了可以進行模型訓練的狀態。使用scikit-learn線性回歸模型進行訓練。 訓練及測試資料共29,764筆，特徵共13種(區域、土地坪數、樓層、最高樓層、建物型態、屋齡、建物總面積、房廳衛數、有無管理組織及電梯) ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-10 col-md-5 col-xxl-4 imgArea3"},[_c('div',{staticClass:"image"},[_c('img',{staticClass:"housePricePC",attrs:{"src":require("../../assets/houseprice.png"),"alt":"lol"}})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row flex-md-row-reverse flex-column-reverse main-contnet2 justify-content-center align-items-center"},[_c('div',{staticClass:"col-10 col-md-5 col-xxl-4 preview4"},[_c('div',[_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/JavaScript.png"),"alt":"lolPic"}})]),_c('div',{staticClass:"title fs-1 fw-bolder mt-1 mt-md-3"},[_vm._v("JavaScript Quiz")]),_c('div',{staticClass:"fs-3 tag fw-bolder mt-1 mt-md-3"},[_vm._v("Work with data")]),_c('div',{staticClass:"fw-bolder mt-2 main-text"},[_c('p',[_vm._v("有關JavaScript的選擇題測驗")])]),_c('div',{staticClass:"linkArea"},[_c('a',{staticClass:"link",attrs:{"href":"/jsquiz"}},[_c('i',{staticClass:"bi bi-box-arrow-up-right"}),_c('div',{staticClass:"linkTxt"},[_vm._v(" GO Try")])])])]),_c('div',{staticClass:"col-10 col-md-5 col-xxl-4 imgArea2"},[_c('div',{staticClass:"image"},[_c('img',{staticClass:"lol-wallpaper",attrs:{"src":require("../../assets/jsquizapp.png"),"alt":"lol"}})])])])
}]

export { render, staticRenderFns }