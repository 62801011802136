<template>
    <footer class="footer" id="section3">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <p class="footer-text">2023 - <strong>Timmy First Project</strong></p>
                </div>
            </div>
        </div>
    </footer>    
</template>
    
    <script>
        export default {
            data(){
                return{
                    
                }
            },
            methods: {
                
            },
            mounted() {
                
               
            },
            beforeDestroy(){
                
            },
            computed:{
                
            },
            watch:{
                
            }
    
    
        }
    </script>
    
    <style scoped>
    .footer {
        background-color: #f5f5f5;
        padding: 20px 0;
        margin-top: 20px;
        border-top: 1px solid #ddd;
    }
    .footer-text {
        color: #777;
        font-size: 14px;
    }
    </style>