<template>
    <div>
        <PortfolioResume />
        <PortfolioList />
        <LearningResource />
        <CommentSection />
        <Footer />
    </div>
</template>

<script>
import LearningResource from "./LearningResource"
import PortfolioList from "./PortfolioList.vue"
import PortfolioResume from "./PortfolioResume.vue"
import CommentSection from "./CommentSection.vue"
import Footer from "@/components/Footer.vue"

export default{
    components:{
        PortfolioResume,
        PortfolioList,
        LearningResource,
        CommentSection,
        Footer
    }
}

</script>

<style>

</style>